.sales-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.sales-title {
    margin-bottom: 30px;
    color: #333;
    font-weight: bold;
}

.language-toggle {
    padding: 10px 20px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 20px;
}

.language-toggle:hover {
    background-color: #45a049;
}

.sales-list {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
}

.sales-list li {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #333;
}

.sales-label {
    font-weight: bold;
    margin-top: 10px;
    color: #555;
}

.error {
    color: red;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .sales-container {
        margin: 20px;
        padding: 10px;
    }

    .sales-title {
        font-size: 1.5rem;
    }

    .language-toggle {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}
.back-arrowS {
    font-size: 24px;
    margin-top: 9px;
    margin-left: 20px;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    color: #333; /* Set color */
  }
  
  .back-arrow:hover {
    color: #000; /* Darker color on hover */
  }