.expenditure-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.expenditure-title {
    margin-bottom: 30px;
    color: #333;
    font-weight: bold;
}

.date-display {
    font-size: 1rem;
    margin-bottom: 20px;
}

.expenditure-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.expenditure-label {
    text-align: left;
}

.date-input {
  -webkit-appearance: none; /* Disables the default style for Chrome, Safari */
  -moz-appearance: none;    /* Disables the default style for Firefox */
  appearance: none;         /* Standard property, currently not fully supported */
}


.expenditure-input,
.date-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 1rem;
    color: #333;
}

/* Specific styles for the date input */
.date-input {
    background-color: #fff;
    cursor: pointer;
}

.save-button {
    padding: 10px 20px;
    background-color:#4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    /* Add space above the button */
}

.save-button:hover {
    background-color: #0056b3;
}

/* ... existing styles ... */

.expenses-display {
    margin-top: 30px;
}

.expense-card {
    background-color: #f0f0f0;
    /* Light gray background */
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    /* Removed position: relative; as it's no longer necessary */
}

/* Style for delete and update buttons */
.delete-button1,
.update-button {
    background-color: #ff4d4d;
    /* Red color for delete button */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
    /* Space above the button */
}

.delete-button1:hover {
    background-color: #ff3333;
    /* Slightly darker red on hover */
}

.update-button {
    background-color: #4CAF50;
    /* Green color for update button */
    color: white;
    margin-left: 10px;
    /* Space between the buttons */
}

.update-button:hover {
    background-color: #45a049;
    /* Slightly darker green on hover */
}

.alert-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.alert-dialog p {
    margin-bottom: 20px;
    text-align: center;
}

.alert-dialog button {
    margin: 0 10px;
    padding: 5px 15px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    /* Rounded corners */
    font-weight: bold;
}

/* Styling for the 'Yes' button */
.alert-dialog .confirm-yes {
    background-color: green;
}

/* Styling for the 'No' button */
.alert-dialog .confirm-no {
    background-color: red;
}

.expenses-report {
    background-color: #f8f9fa;
    /* Light grey background */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    padding: 20px;
    /* Spacing inside the container */
    margin-top: 20px;
    /* Add space above the container */
    margin-bottom: 20px;
    /* Add space below the container */
}

.expenses-report h4 {
    color: #333;
    /* Dark grey color for text */
    border-bottom: 2px solid #007bff;
    /* Blue bottom border */
    padding-bottom: 5px;
    /* Space between text and border */
    margin-bottom: 16px;
    /* Space below the heading */
}

.expenses-report div {
    background-color: #fff;
    /* White background for each entry */
    padding: 10px;
    margin-bottom: 8px;
    /* Space between entries */
    border-radius: 4px;
    /* Rounded corners for entries */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* Subtle shadow for entries */
}

/* Style for the total amount */
.expenses-report div:last-child {
    font-weight: bold;
    /* Make the total amount bold */
    color: #28a745;
    /* Green color for the total amount */
}

/* Styling for the month-year headings */
.expenses-report h3 {
    background-color: #007bff;
    /* Blue background */
    color: white;
    /* White text */
    padding: 10px;
    border-radius: 5px;
    /* Rounded corners */
    margin-top: 20px;
    /* Space above each heading */
    margin-bottom: 10px;
    /* Space below each heading */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    text-align: center;
    /* Centered text */
}

/* Styling for the expenses display */
.expenses-display {
    margin-bottom: 20px;
    /* Space below each expenses display section */
}
.toggle-button {
    margin-left: 10px; /* Space from the month-year text */
    padding: 5px 10px;
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer;
    font-size: 0.9em; /* Adjust font size */
  }
  
  .toggle-button:hover {
    background-color: #367c39; /* Darker green on hover */
  }
  .toggle-all-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 5px 10px; /* Smaller padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px; /* Smaller font size */
    margin: 8px 4px; /* Adjusted margin */
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .toggle-all-button:hover {
    background-color: white;
    color: #4CAF50;
    border: 1px solid #4CAF50;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .global-toggle-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-image: linear-gradient(to right, #56ab2f 0%, #a8e063 100%);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-right: 20px;
    outline: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .global-toggle-button:hover {
    background-image: linear-gradient(to right, #a8e063 0%, #56ab2f 100%);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  
  .global-toggle-button:active {
    transform: translateY(2px);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  }
  
  .language-toggle {
    background-color: #4CAF50; /* Green background */
    border: none;
    color: white; /* White text */
    padding: 10px 20px;
    text-align: right; 
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
   
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 20px; /* Rounded corners */
}

.language-toggle:hover {
    background-color: #45a049; /* Darker shade of green */
}


  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .custom-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%; /* Adjust width for smaller screens */
    z-index: 1001;
  }
  
  .custom-modal-content {
    margin-bottom: 20px;
  }
  
  .custom-modal .close-button {
    float: right;
    padding: 5px 10px;
    background: #f44336;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: -20px;
    margin-right: -20px;
  }
  
  .custom-modal button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
    opacity: 0.9;
  }
  
  .custom-modal button:hover {
    opacity: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .custom-modal {
      width: 80%; /* Increase width on smaller screens for better visibility */
      padding: 15px; /* Adjust padding for smaller screens */
    }
  
    .custom-modal-content p {
      font-size: 14px; /* Adjust font size for readability on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .custom-modal {
      width: 95%; /* Utilize more screen space on very small devices */
      padding: 10px; /* Reduce padding to save space */
    }
  
    .custom-modal .close-button {
      padding: 4px 8px; /* Adjust the size of the close button for smaller screens */
      margin-top: -15px;
      margin-right: -15px;
    }
  
    .custom-modal-content p {
      font-size: 12px; /* Further reduce font size for very small screens */
    }
  }
  
  @media (max-width: 768px) {
    .expenditure-container {
        margin: 20px;
        padding: 10px;
    }

    .expenditure-title {
        font-size: 1.5rem;
    }
    
    .save-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

.back-arrow {
  font-size: 24px;
  margin-top: -9px;
  margin-left: 3px;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #333; /* Set color */
}

.back-arrow:hover {
  color: #000; /* Darker color on hover */
}
.custom-dropdown {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute; /* Ensures the dropdown is placed below the input */
  width: 100%;
  z-index: 1; /* Keeps it above other elements */
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* Download Button */
.download-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-image: linear-gradient(to right, #56ab2f 0%, #a8e063 100%);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 20px;
  outline: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin: 10px 0; /* Adjust margin if necessary */
  width: auto; /* Prevent full width */
  display: inline-block; /* Align it with other buttons */
}

.download-button:hover {
  background-image: linear-gradient(to right, #a8e063 0%, #56ab2f 100%);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.download-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}
